@import "../../utils/includes.scss";

//
// Fonts
//

$fontFamily: 'LandRoverWeb-Medium', sans-serif;
$boldFontFamily: 'LandRoverWeb-Bold', Arial;
$boldFontWeight: 700;
$titleFontFamily: $boldFontFamily;
$titleFontWeight: $boldFontWeight;
$isUppercase: uppercase;

//
// Colors
//

$textColor: #0C121C;
$linkColor: #005A2B;
$labelColor: #999999;
$formColor: #0C121C;
$utilColor: #999999;
$priceColor: #005A2B;
$lightColor: #999999;
$btnColorAlpha: #ffffff;
$btnColorBeta: #ffffff;
$btnColorStep: #ffffff;
$titleColor: #0C121C;
$titleSecondaryColor: #ffffff;
$subtitleColor: #0C121C;
$subtitleSecondaryColor: #ffffff;
$successColor: #005a2b;
$warningColor: #005A2B;
$errorColor: #ff3526;

$bgPrimaryColor: #ffffff;
$bgSecondaryColor: transparent;

$bgBtnAlpha: #0C121C;
$colorBtnAlpha: #fff;

$bgBtnBeta: #f0f0f0;
$colorBtnBeta: #0C121C;
$borderBtnBeta: #f0f0f0;

$colorBtnOutlineAlpha: #0C121C;
$colorBtnOutlineBeta: #0C121C;

$paddingButtonPrimary: 16px 14px;
$paddingButtonSecondary: 11px 15px;
$borderRadiusButton: 0px;

$borderRadiusForm: 0px;
$borderColorForm: #000;
$borderSizeForm: 1px solid;

$calculatorTabsColor: #16181e;
$calculatorTabsTextColor: #dedede;
$calculatorCloseTabs: #505050;
$calculatorCloseTabsColor: #e3e3e3;
$calculatorMainColor: #ffffff;
$calculatorButton: #f0710a;

$garageBackground: #ffffff;
$garageTextColor: #0C121C;
$garageCarNoteColor: #f8f8f8;

$bgTopbarLogin: #4A4F54;
$colorTopbarLogin: #c8c9cb;

$progressBarTitleBG: #005A2B;
$progressBarTitleColor: #ffffff;
$progressBarTitleCloseBG: #ffffff;
$progressBarTitleCloseColor: #00426b;
$progressBarCloseButtonBG: #005A2B ;
$progressBarCloseButtonColor: #ffffff;

$paymentCalculatorClosedBG: #4A4F54;
$paymentCalculatorClosedColor: #B0B7BB;
$paymentCalculatorPanelBG: #21232E;
$paymentCalculatorTermWrapperBG: #4a4f54;
$paymentCalculatorTermWrapperColor: #aaa;
$paymentCalculatorTermSelectedWrapperBG: #0c121c;
$paymentCalculatorTermSelectedColor: #fff;
$paymentCalculatorTermBG: #0c121c;
$paymentCalculatorTermColor: #e4e8e8;

$fontSize-1: 45px;
$fontSizeLineHeight-1: 50px;

$carTaglineColor : #ffffff;

// ========
// Styles
// ========
@include breakpoint(mobile){
    .widget-sr{
        &.makes__land-rover{
            .vehicle-tile-rebate-single{
                background: $bgBtnAlpha;
                .is-price{
                    color: $colorBtnAlpha;
                }
                span{
                    color: $colorBtnAlpha;
                }
            }
            .vdp-used-form{
                .cta-visit{
                    text-align: center;
                    position: relative;
                    left: 0;
                    transform: translateX(0);
                }
            }
        }
    }
}
.widget-sr{
    @include clearfix;
    width: 100%;

    &.makes__land-rover{
        font-family: $fontFamily;
        font-size: 14px;
        color: $textColor;
        background-color: $bgPrimaryColor;
        &.Checkout{
            background-color: $bgSecondaryColor
        }
        .InputText.styled-placeholder{
            height: 50px;
        }
        .vdp-used-form .InputText.styled-placeholder{
            margin: 0 10px 7px;
        }
        .vehicle-overview__title{
            margin: 0 0 50px;
        }

        .sr-button-2{
            background: $bgBtnBeta !important;
            color: $colorBtnBeta !important;
            border: 1px solid $colorBtnBeta !important;
            &:hover{
                background: $colorBtnBeta !important;
                color: $bgBtnBeta !important;
                border: 1px solid $colorBtnBeta !important;
            }
        }

        .listing-used .listing-used-inner-content .listing-used-header-refine button,
        .listing-used-payment .listing-used-set-budget button,
        .listing-used .listing-used-inner-content .listing-used-header-refine button::after,
        .listing-used-payment .listing-used-set-budget button::after{
            color: #EBEBEB;
        }
        .catalog-filter-type-list--single{
            .selected{
                color: #005A2B;
            }
        }
        .vehicle-item {
            .taxes-detail{
                font-family: $fontFamily;
                font-weight: $boldFontWeight;
            }
        }
        .showroom-build-and-price{
            .warning{
                background-color: #005A2B;
            }
        }
        .section-catalog-filter {
            .catalog-filter-type-name,
            .catalog-filter-type-all{
                color: #0c121c;
            }
        }
        .vehicle-tile-rebate-single{
            color: #0c121c;
        }
        .vehicle-item {
            .vehicle-cost,
            .vehicle-name,
            .vehicle-tile-rebate-single,
            .vehicle-payment-title,
            .vehicle-price,
            .vehicle-price-label{
                color: #0c121c;
            }
            .vehicle-payment-rate,
            .vehicle-payment-cashdown,
            .vehicle-payment-cost-credit{
                color: #586167;
            }
            .taxes-detail{
                color: #B0B7BB;
            }
        }
        .VehicleDisclaimersContent{
            color: #B0B7BB;
        }
        .method-button-payment-bar{
            button{
                color: #0c121c;
            }
        }
        .listing-used-filters-head{
            div{
                color: #0c121c;
            }
        }
        .listing-used-filters-bloc{
            div{
                color: #0c121c;
            }
        }
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .taxes-detail,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-rate,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-amount .vehicle-payment-amount-note,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-cashdown,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-cost-credit,
        .vehicle-item .vehicle-description .vehicle-name .vehicle-body,
        .vehicle-item .vehicle-description .vehicle-cost .vehicle-price,
        .listing-tile .listing-tile-drivePowerTrains,
        .listing-new-tile .listing-new-tile-drivePowerTrains,
        .cash-section.tile-payment-info .payment-row,
        .listing-new-tile .listing-tile-package-description,
        .listing-new-tile .listing-new-tile-spec .listing-tile-specification-specifics .listing-tile-specification-stock,
        .payment-summary-section .listing-tile-all-price .disclaimer-price.is-light
        {
            color: $textColor;
        }
        .widget-vehicle-quote-btn-secondary.sr-button-outline-2.is-alternate{
            color: #ffffff;
            background: #0C121C;
            border-color: #0C121C;
        }
        #button-register.sr-button-1,
        #calculator-toggle-button.sr-button-1{
            color: #0c121c;
            background: #b0b7bb;
        }
        .widget-vehicle-quote-btn-secondary.sr-button-outline-2{
            color: #ffffff;
            background: #0C121C;
            border-color: #0C121C;
        }
        .vehicleBanner-personsViewed .amount.is-price{
            color: #ffffff;
        }
        .listing-tile .listing-tile-image .car-tagline {
            color: $carTaglineColor;
        }

        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper{
            .TransactionSummaryUnit--single.is-validate{
                border-bottom-color: #0c121c!important;
            }
        }
        .TransactionSummaryUnit--single{
            .validFlag:before{
                background-color: transparent;
                background-image: -webkit-linear-gradient(304deg,transparent 50%,#0c121c 55%) !important;
            }
        }
        .TransactionProgressBar--progression{
            background-color: #0c121c !important;
        }
        .confirm_deposit--icon,
        .confirm_financing--icon{
            border: 1px solid #0c121c !important;
            .icon,.icon-Crochet{
                color: #0c121c !important;
            }
        }
        .confirm_deposit--title,
        .confirm_financing--title{
            color: #0c121c;
        }
        .section-checkout-header__progressBar-stage.validated{
            .picto-showroom:first-child{
                color: #0c121c;
            }
        }
        .Appointment__content .appointment__steps--single.is-validated {
            .icon-calendar{
                color: #0c121c !important;
            }
        }
        .doneLabel {
            color: #0c121c !important;
            .icon-Crochet{
                color: #0c121c !important;
            }
        }

        .message-handler{
            &.warning,
            &.success,{
                background-color: #0c121c !important;
            }
        }
        .has-success-background{
            background-color: #0c121c !important;
        }
        .vdp-used-form{
            .cta-visit{
                text-align: center;
                position: relative;
                left: -50%;
                transform: translateX(100%);
            }
        }
        &.-hiddenBuyOnline {
            .showroom-vdp-used,
            .showroom-vdp-new{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        &.-hiddenCatalogBuyOnline {
            .showroom-build-and-price__content{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        .payment-calculator-wrapper{
            z-index: auto;
        }

        &.trade-is-on-top{
            .listing-used-car-search>div:last-child {
                display: flex;
                flex-direction: column;
                #instant-estimate-bar-wrapper{
                    order: 1;
                    margin-top: 0 !important;
                }
                .listing-tiles{
                    order: 2;
                }
                .listing-used-button-loading{
                    order: 3;
                }
            }
        }
        // **********************
        // Header B&B
        // **********************
        @include headerBuildBuy;

        // **********************
        // Barre de progression
        // **********************
        @include progressBar;

        // **********************
        // Formulaires
        // **********************
        @include forms;

        // **********************
        // Par sections
        // **********************
        @include paymentCalculator;
        @include checkout;
        @include sidebarsummary;
        @include login;
        @include garage;
        @include protections;

        @include titles;
        @include buttons;
        @include links;
        @include generics;
        @include baseline;
    }
}

@include breakpoint(desktop){
    .widget-sr{
        &.makes__land-rover{
            .payment-calculator-wrapper{
                z-index: 9;
            }
        }
    }
}